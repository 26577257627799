@import "../../../styles/fonts.scss";
@import "../../../styles/_mixins.scss";

.content {
  padding: 0 16px;
  color: var(--gray-description);

  h2,
  h3,
  h4,
  h5,
  p,
  span,
  li {
    line-height: 1.5;
  }

  h2 {
    margin-bottom: 30px;
    font-weight: bold;
    color: var(--black);

    @media screen and (min-width: 279px) and (max-width: 767px) {
      font-size: 1.5rem;
    }

    @media screen and (min-width: 768px) {
      font-size: 2rem;
    }
  }

  h3 {
    margin-bottom: 10px;
    font-weight: bold;
    color: var(--black);

    @media screen and (min-width: 279px) and (max-width: 767px) {
      font-size: 1.125rem;
    }

    @media screen and (min-width: 768px) {
      font-size: 1.25rem;
    }
  }

  h5 {
    display: inline-flex;
    font-weight: bold;
    color: var(--black);

    @media screen and (min-width: 279px) and (max-width: 767px) {
      font-size: 1rem;
    }

    @media screen and (min-width: 768px) {
      font-size: 1.25rem;
    }
  }

  ol {
    list-style: decimal;
  }

  p,
  ul li,
  ol li {
    @media screen and (min-width: 279px) and (max-width: 767px) {
      font-size: 1.125rem;
    }

    @media screen and (min-width: 768px) {
      font-size: 1.25rem;
    }
  }

  p {
    margin: 0 0 25px;
    color: var(--black);
  }

  strong {
    font-weight: bold !important;
  }

  a {
    margin-left: 5px;
    color: var(--primary);
    transition: var(--transition);

    &:hover {
      color: var(--primary-dark);
    }

    &[data-style="button"] {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      max-width: 300px;
      min-height: 53px;
      font-weight: 500 !important;
      padding: 0 24px;
      margin: 10px 0;
      background-color: var(--primary);
      color: var(--white);
      outline: none;
      border: none;
      transition: var(--transition);

      &:hover {
        background-color: var(--primary-dark);
      }

      @media screen and (max-width: 1059px) {
        @include apply-font(preformatted, mobile, regular);
      }

      @media screen and (min-width: 1060px) {
        @include apply-font(preformatted, desktop, regular);
      }
    }
  }

  img {
    width: 100%;
    object-fit: contain;
  }

  img + h3,
  img + h2 {
    margin-top: 30px;
  }

  img + p {
    margin-top: 25px;
  }

  ul {
    list-style: disc;

    & + img {
      margin-top: 30px;
    }
  }

  ul,
  ol {
    padding-left: 15px;
    margin: 0 0 25px;

    li {
      padding-left: 20px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul,
  ol {
    & + img {
      margin-top: 50px;
    }
  }

  blockquote {
    padding-top: 10px;
    font-style: italic;
    margin-bottom: 15px;
    border-left: 2.5px solid var(--gray-border);
    padding-left: 20px;
    margin: 0 auto 35px;
  }

  span {
    font-size: 1.125rem;
    color: var(--gray-description);
  }

  & > span {
    display: flex;
    margin: 15px 0 25px;
  }

  video {
    max-width: 980px;
    margin-bottom: 40px;
  }

  button {
    display: flex;
    align-items: center;
    width: fit-content;
    background-color: transparent;
    font-size: 0.875rem;
    line-height: 0.8125rem;
    color: var(--black);
    border: none;
    outline: none;
    transition: var(--transition);
    margin-bottom: 35px;
    margin-top: 20px;

    svg {
      display: flex;
      margin-right: 5px;
      path {
        transition: var(--transition);
      }
    }

    &:hover {
      color: var(--primary);

      svg {
        path {
          fill: var(--primary);
        }
      }
    }
  }

  table {
    width: 100%;

    @media screen and (min-width: 279px) and (max-width: 767px) {
      max-width: 100%;
      overflow-x: auto;
      display: block;
      margin: 0 0 50px;

      td {
        min-width: 165px;
        max-width: 185px;
      }
    }

    @media screen and (min-width: 768px) {
      background-color: var(--gray-light-menu);
      margin: 0 auto 50px;
    }

    tr {
      @media screen and (max-width: 767px) {
        padding: 0 15px;
      }

      @media screen and (min-width: 768px) and (max-width: 1049px) {
        padding: 0 15px 0 20px;
      }

      @media screen and (min-width: 1050px) {
        padding: 0 50px 0 55px;
      }

      @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      }

      &:first-child {
        border-bottom: 1px solid var(--gray-secondary);

        td {
          padding: 18px 0 20px;
          font-weight: 500;
          color: var(--gray-dark);
        }
      }

      &:not(:first-child) {
        td {
          font-size: 16px;
          line-height: 22px;
          padding: 23px 10px 27px 0;
          color: var(--black);
          border-bottom: 1px solid var(--gray-border);
        }
      }
    }
  }
}
